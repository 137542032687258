import React, { useContext } from 'react'
import { UneeqContext, useUneeqError } from 'uneeq-react-core'
import { FatalError, Warning } from 'uneeq-react-ui'
import Error from './Error'
import { Flex } from 'rebass'
import styles from './styles'

const ErrorContainer = ({ children, sx }: any) => {
  return <Flex sx={{ ...styles.errorContainer, ...sx }}>{children}</Flex>
}

const MayaErrors = () => {
  const error = useUneeqError()
  const { dispatch } = useContext(UneeqContext)
  const clearError = () => dispatch({ type: 'clearError' })

  switch (error?.level) {
    case 'warning':
      return (
        <ErrorContainer>
          <Warning message={error.message} />
        </ErrorContainer>
      )
    case 'error':
      return (
        <ErrorContainer>
          <Error message={error.message} />
        </ErrorContainer>
      )
    case 'fatal':
      return (
        <ErrorContainer>
          <FatalError errorMessage={error.message} clearError={clearError} />
        </ErrorContainer>
      )
    default:
      return null
  }
}

export default MayaErrors
