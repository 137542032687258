const questionReducer = (config?: any) => (state: any, action: any) => {
  switch (action.type) {
    case 'mayaMessage':
      switch (action?.payload?.type) {
        case 'question':
          return {
            ...state,
            mayaQuestion: action.payload,
            questionLoading: false,
            hideQuestionTitle:
              state.speak &&
              !state.sessionPaused &&
              (state.mayaConfig?.hideQuestion || action.payload?.hideQuestion)
          }
        case 'autocomplete':
          return { ...state, autocompleteOptions: action.payload.matches }
        case 'response':
          return {
            ...state,
            questionLoading: true
          }
        default:
          return state
      }
    case 'clearQuestionLoading':
      return {
        ...state,
        questionLoading: false
      }
    case 'pauseSession':
      return {
        ...state,
        hideQuestionTitle: false
      }
    case 'resumeSession':
      return {
        ...state,
        hideQuestionTitle:
          state.speak &&
          (state.mayaConfig?.hideQuestion || state.mayaQuestion?.hideQuestion)
      }
    default:
      return state
  }
}

export default questionReducer
