import React, { useContext, useEffect } from 'react'
import { Flex } from 'rebass'
import { isTablet, isMobileOnly, isAndroid } from 'react-device-detect'
import { Uneeq } from 'uneeq-js'
import {
  UneeqAvatar,
  UneeqLocalVideo,
  UneeqProvider,
  UneeqContext,
  useUneeqState
} from 'uneeq-react-core'
import {
  PrivacySummary,
  EndSessionConfirm,
  EscalationForm
} from 'uneeq-react-ui'
import config from '../../config'
import { loadingGatewayReducer } from '../../customPlugins/LoadingGateway'
import mayaEchoMiddleware from '../../customPlugins/mayaEchoMiddleware'
import mayaErrorsReducer from '../../customPlugins/MayaError/mayaErrorsReducer'
import MayaInformation from '../../customPlugins/MayaInformation/MayaInformation'
import mayaInformationMiddleware from '../../customPlugins/MayaInformation/mayaInformationMiddleware'
import mayaTranscriptReducer from '../../customPlugins/MayaTranscript/mayaTranscriptReducer'
import mayaStatementReducer from '../../customPlugins/MayaStatement/mayaStatementReducer'
import MayaStatement from '../../customPlugins/MayaStatement/MayaStatement'
import MayaUi from '../../customPlugins/MayaUi'
import { questionReducer } from '../../customPlugins/Question'
import Question from '../../customPlugins/Question/Question'
import { getToken, endSession as endMayaSession } from '../../socket'
import mayaInformationReducer from '../../customPlugins/MayaInformation/mayaInformationReducer'
import MayaErrors from '../../customPlugins/MayaErrors/MayaErrors'
import assets from '../assets'
import UIToolbar from '../UIToolbar'
import closeSessionMiddleware from '../../customPlugins/CloseSession/closeSessionMiddleware'
import MayaTimeout from '../../customPlugins/MayaTimeout'
import styles from './styles'
import SmallScreen from './SmallScreen'
import mayaConfigReducer from '../../customPlugins/MayaConfig/mayaConfigReducer'
import pauseSessionMiddleware from '../../customPlugins/PauseSession/pauseSessionMiddleware'
import pauseSessionReducer from '../../customPlugins/PauseSession/pauseSessionReducer'
import {
  mayaBandwidthReducer,
  MayaBandwidth
} from '../../customPlugins/MayaBandwidth'
import Transcript from '../../customPlugins/MayaTranscript/Transcript'
import {
  MayaConfirmLeave,
  mayaConfirmLeaveReducer
} from '../../customPlugins/MayaConfirmLeave'
import {
  MayaDocumentTitle,
  mayaDocumentTitleReducer
} from '../../customPlugins/MayaDocumentTitle'
import sessionIDMiddleware from '../../customPlugins/SessionID/sessionIDMiddleware'
import Gateway from '../../customPlugins/LoadingGateway/Gateway'
import useAnswerQuestionTimeout from '../hooks/useAnswerQuestionTimeout'

const {
  video: { permissions }
} = assets

// state slice to close all modals
export const closeModals = {
  menuOpen: false,
  settingsOpen: false,
  endConfirmOpen: false,
  timeoutOpen: false,
  confirmLeave: false,
  privacyOpen: false,
  error: null
}

// state slice to close all dialogs
export const closeDialogs = {
  question: null,
  transcriptOpen: false,
  error: null
}
const reducers = [
  mayaTranscriptReducer({
    onOpen: { ...closeModals, ...closeDialogs }
  }),
  mayaErrorsReducer,
  pauseSessionReducer(),
  questionReducer(),
  mayaStatementReducer(),
  mayaBandwidthReducer(config.maxLoadingTimeInMs),
  loadingGatewayReducer(),
  mayaConfigReducer(),
  mayaInformationReducer(),
  mayaConfirmLeaveReducer,
  mayaDocumentTitleReducer(config.defaultDocumentTitle)
]

const debugMiddleware = () => (state: any, action: any) => {
  console.info(action)
  return state
}
const middleware: any = [
  debugMiddleware(),
  mayaEchoMiddleware(),
  mayaInformationMiddleware(),
  closeSessionMiddleware({ endMayaSession }),
  pauseSessionMiddleware(),
  sessionIDMiddleware()
]

interface DigitalHumanContentProps {
  restart: () => void
  loadingTips: Array<any>
  speak?: boolean
  embeddedMode: boolean
}

const DigitalHumanContent = ({
  restart,
  loadingTips,
  speak,
  embeddedMode
}: DigitalHumanContentProps) => {
  const { dispatch } = useContext(UneeqContext)
  const { sessionPaused, transcriptOpen } = useUneeqState()
  useAnswerQuestionTimeout(config.maxTimeWaitingForAnswer)

  useEffect(() => {
    dispatch({ type: 'setSpeak', payload: speak })
  }, [speak, dispatch])
  const { restartSession } = useContext(UneeqContext)

  const finalRestart = () => {
    endMayaSession()
    restartSession()
  }

  const isSmallScreen = isTablet || isMobileOnly

  const orientation =
    // eslint-disable-next-line no-restricted-globals
    (screen.orientation || {}).type ||
    // eslint-disable-next-line no-restricted-globals
    (screen as any).mozOrientation ||
    // eslint-disable-next-line no-restricted-globals
    (screen as any).msOrientation
  const isLandscape =
    orientation === 'landscape-primary' || orientation === 'landscape-secondary'

  return (
    <Flex
      sx={{
        ...styles.container,
        ...(isSmallScreen
          ? !isAndroid
            ? {
                '@media only screen and (orientation: landscape)': {
                  '& > div': {
                    display: 'none'
                  },
                  '& > div:first-child': {
                    display: 'flex'
                  }
                }
              }
            : isLandscape
            ? {
                '& > div': {
                  display: 'none'
                },
                '& > div:first-child': {
                  display: 'flex'
                }
              }
            : {}
          : {})
      }}
    >
      <SmallScreen />
      <UneeqAvatar
        sx={{
          height: 'auto',
          flex: 1,
          display: 'flex',
          marginLeft: sessionPaused ? '-5000000px' : 0
        }}
      />

      <MayaDocumentTitle />
      <MayaUi />
      <Flex
        sx={{
          ...styles.interactionContainer,
          ...(transcriptOpen ? { height: '100%' } : {}),
          ...(!speak || sessionPaused
            ? {
                left: ['unset', 'unset', 'unset', 'unset', '50%', '50%'],
                transform: [
                  'translateX(0)',
                  'translateX(0)',
                  'translateX(0)',
                  'translateX(0)',
                  'translateX(-50%)',
                  'translateX(-50%)'
                ]
              }
            : {})
        }}
      >
        <Transcript />
        <MayaBandwidth />
        <Question speak={speak} />
        <MayaInformation />
      </Flex>
      <MayaStatement />
      {/* Must be present but we want it hidden */}
      <UneeqLocalVideo style={{ display: 'none' }} />
      <Gateway
        restart={finalRestart}
        video={permissions}
        embeddedMode={embeddedMode}
      >
        <UIToolbar />
        {/* Modals */}
        <MayaConfirmLeave onConfirm={finalRestart} />
        <MayaTimeout endSession={finalRestart} />
        <PrivacySummary />
        <EndSessionConfirm restart={finalRestart} />
        <EscalationForm restart={finalRestart} />
        <MayaErrors />
      </Gateway>
    </Flex>
  )
}

const DigitalHuman = ({
  postInit,
  speak,
  onTimedOut,
  onSessionEnded,
  restart,
  loadingTips,
  embeddedMode
}: DigitalHumanProps) => {
  return (
    <UneeqProvider
      config={config}
      reducers={reducers}
      speak={speak}
      middleware={middleware}
      getToken={getToken}
      postInit={postInit}
      restart={restart}
      onTimedOut={onTimedOut}
      onSessionEnded={onSessionEnded}
    >
      <DigitalHumanContent
        speak={speak}
        restart={restart}
        loadingTips={loadingTips}
        embeddedMode={embeddedMode}
      />
    </UneeqProvider>
  )
}

interface DigitalHumanProps {
  onTimedOut: () => void
  onSessionEnded: () => void
  postInit?: (uneeq: Uneeq) => void
  token?: string
  speak?: boolean
  restart: () => void
  loadingTips: Array<any>
  embeddedMode: boolean
}

export default DigitalHuman
