import React from 'react'
import { Button, Text, Flex, Heading, Box } from 'rebass'
import styles from './styles'
import { trackHandler } from 'uneeq-react-core'
import { Overlay } from 'uneeq-react-ui'
import { ReactComponent as ExclamationIcon } from './exclamation.svg'

interface FatalErrorProps {
  errorTitle?: string
  errorMessage?: string
  clearError?: () => void
  buttonText?: string
  children?: React.ReactNode
}
const FatalError: React.FC<FatalErrorProps> = ({
  errorTitle = '404 Error',
  errorMessage = `Sorry, something's gone wrong (#6489)`,
  clearError,
  buttonText = 'Back to homepage',
  children
}) => (
  <Overlay>
    <Flex
      sx={{
        ...styles.error.container,
        backgroundColor: 'backgroundPrimary',
        p: 10
      }}
    >
      <Flex sx={styles.fatal.icon}>
        <ExclamationIcon />
      </Flex>

      <Heading sx={styles.fatal.heading}>{errorTitle}</Heading>
      {!children && <Text sx={styles.fatal.message}>{errorMessage}</Text>}
      {children !== undefined && (
        <Flex sx={styles.fatal.childrenContainer}>{children}</Flex>
      )}
      {clearError && (
        <Button
          sx={styles.fatal.backButton}
          variant="primary"
          onClick={trackHandler(clearError, 'close-error-btn')}
        >
          {buttonText}
        </Button>
      )}
    </Flex>
  </Overlay>
)

export default FatalError
