export enum THEMES {
  DEFAULT,
  DARK
}

const colors = {
  text: '#000000',
  textLight: '#393939',
  textAlternate: '#FFFFFF',

  primary: '#275DAF',
  primaryLight: '#4287f5',
  primaryText: '#FFFFFF',

  secondary: '#22b5a1',
  secondaryLight: '#4bd6c4',
  secondaryText: '#FFFFFF',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimary: 'rgba(255, 255, 255, 0.95)',
  backgroundSecondary: 'rgba(51, 51, 51, 0.1)',
  backgroundSecondaryHover: 'rgba(51, 51, 51, 0.3)',
  backgroundOverlay: 'rgb(51, 51, 51, 0.5)',
  error: '#BF2828',
  warning: '#FBBD1D',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#4287f5',
  timeoutTrailColor: '#BDC0C7'
}

const darkColors = {
  text: '#FFFFFF',
  textLight: '#393939',
  textAlternate: '#FFFFFF',

  primary: '#275DAF',
  primaryLight: '#4287f5',
  primaryText: '#FFFFFF',

  secondary: '#22b5a1',
  secondaryLight: '#4bd6c4',
  secondaryText: '#FFFFFF',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(51,51,51,0.95)',
  backgroundPrimary: 'rgba(51,51,51,0.95)',
  backgroundSecondary: 'rgba(255, 255, 255, 0.1)',
  backgroundOverlay: 'rgb(255, 255, 255, 0.3)',

  backgroundSecondaryHover: 'rgba(255, 255, 255, 0.3)',

  error: '#BF2828',
  warning: '#FBBD1D',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#4287f5',
  timeoutTrailColor: '#BDC0C7'
}

const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

export const theme = (theme: THEMES) => ({
  colors: {
    ...(theme === THEMES.DARK ? darkColors : colors),

    // PTT animation
    pttMobileGlow: hex2rgba(colors.primaryLight, 0.2), // Mobile - glow around button while recording
    // PTT desktop colors & gradients
    pttColRecording: 'white',
    pttImgRecording: `radial-gradient(circle, ${colors.primaryLight} 0%, white 100%)`,
    pttColSending: 'primaryLight',
    pttImgSending: `radial-gradient(circle, white 0%,${colors.primaryLight} 100%)`,
    pttColWaiting: 'primaryLight',
    pttImgWaiting: 'primary'
  },
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'inherit'
  },
  fontSizes: ['14px', '15px', '16px', '20px', '22px', '24px', '30px'],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: '31px'
  },
  space: [
    0,
    4,
    8,
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    28,
    32,
    40,
    48,
    56,
    64,
    80,
    100,
    128
  ],
  radii: {
    button: 2,
    buttonBig: 4,
    input: 1,
    card: 8
  },
  shadows: {
    card: '0 4px 4px rgba(0, 0, 0, 0.25)'
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
      pb: 2,
      mt: 3,
      mx: 4
    }
  },
  variants: {
    link: {
      color: 'primary'
    },
    card: {
      bg: 'white',
      color: 'textLight',
      borderRadius: 'card',
      p: [6, 6, 12, 12, 12, 12],
      boxShadow: 'card'
    },
    modal: {
      variant: 'variants.card',
      label: 'modal',
      width: ['92%', '92%', 850],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    transcript: {
      label: 'Transcript',
      borderRadius: '3px',
      overflow: 'hidden',
      maxHeight: '99%',
      color: 'text',
      '& .transcript-header': {},
      '& .transcript-content': {
        fontSize: ['14px', '14px', '18px', '18px', '18px', '18px'],
        px: 5,
        pb: 5,
        height: ['100%', '100%', '100%', '100%', 'auto', 'auto']
      },
      '& .transcript-footer': {
        height: 45,
        position: ['fixed', 'fixed', 'fixed', 'fixed', 'static'],
        minHeight: '45px',
        bottom: [0, 0, 0, 0, 'auto', 'auto'],
        backgroundColor: 'white',
        width: '100%',
        p: 2
      }
    }
  },
  buttons: {
    // TODO
    unstyled: {
      background: 'transparent',
      color: 'secondary',
      fontFamily: 'inherit',
      fontSize: 0
    },
    primary: {
      fontFamily: 'inherit',
      borderRadius: '4px',
      fontWeight: '600',
      fontSize: ['11px', '11px', '14px', '14px', '14px', '14px'],
      lineHeight: ['13px', '13px', '17px', '17px', '17px', '17px'],
      p: '8px',
      transition: 'all 0.4s',
      cursor: 'pointer',
      backgroundColor: 'primary',
      border: '1px solid transparent',
      borderColor: 'primary',
      whiteSpace: 'nowrap',
      '& svg, & img': {
        fill: 'currentColor'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'primaryText',
      '& img, & svg': {
        mr: 2
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'primaryLight'
        }
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: 'primary',
        '--box-shadow-color': 'primary',
        boxShadow: '0 0 3px var(--box-shadow-color)',
        borderColor: 'primaryLight'
      },
      '&:disabled': {
        opacity: 0.2
      }
    },
    primaryInverted: {
      variant: 'buttons.primary',
      bg: 'black',
      borderColor: 'primary',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(1, 99, 172, 0.6)'
        }
      },
      '&:focus': {
        outline: 'none',
        '--box-shadow-color': 'primary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        borderColor: 'grey',
        color: '#E3E5E8'
      }
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'secondaryText',
      backgroundColor: 'secondary',
      borderColor: 'secondary',
      '& svg, & img': {
        // TODO
        // fill: 'currentColor'
        fill: 'red'
      },
      '& img, & svg': {
        mr: 2
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'secondaryLight'
        }
      },
      '&:focus': {
        backgroundColor: 'secondary',
        '--box-shadow-color': 'secondary',
        boxShadow: '0 0 3px var(--box-shadow-color)',
        borderColor: 'secondaryLight'
      },
      '&:disabled': {
        backgroundColor: 'greyDark',
        color: '#85898E'
      }
    },
    secondaryInverted: {
      variant: 'buttons.secondary',
      color: 'secondary',
      background: 'white',
      borderColor: 'secondary',
      '& svg, & img': {
        // fill: 'currentColor'
        fill: 'red'
      },
      '& img, & svg': {
        // mr: 2
        mr: 20
        // TODO
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: '#2E2818'
        }
      },
      '&:focus': {
        borderColor: 'secondary',
        '--box-shadow-color': 'secondary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        borderColor: 'greyDark',
        color: '#85898E'
      }
    },
    outline: {
      color: 'textAlternate',
      fontWeight: '300',
      cursor: 'pointer',
      border: '1px solid #FFFFFF',
      transition: 'all 1s',
      backgroundColor: 'transparent',
      borderRadius: 50,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.1)'
        }
      },
      '&:focus': {
        outline: 'none',
        border: '1px solid transparent',
        borderColor: 'white',
        '--box-shadow-color': 'transparent',
        boxShadow: 'none'
      },
      '&:disabled': {
        backgroundColor: 'thirdGray',
        color: 'fourthGray'
      }
    },
    white: {
      fontFamily: 'inherit',
      borderRadius: 'button',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '-0.01em',
      p: '8px',
      transition: 'all 0.4s',
      cursor: 'pointer',
      backgroundColor: '#fff',
      border: '1px solid transparent',
      whiteSpace: 'nowrap',
      '& svg, & img': {
        fill: 'currentColor'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#333333',
      '& img, & svg': {
        mr: 2
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.7)'
        }
      },
      '&:focus': {
        backgroundColor: '#fff',
        '--box-shadow-color': 'primary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        opacity: 0.2
      }
    },
    whiteInverted: {
      fontFamily: 'inherit',
      borderRadius: '4px',
      fontWeight: 'normal',
      fontSize: ['11px', '11px', '14px', '14px', '14px', '14px'],
      lineHeight: ['13px', '13px', '17px', '17px', '17px', '17px'],
      letterSpacing: '-0.01em',
      p: '8px',
      transition: 'all 0.4s',
      cursor: 'pointer',
      bg: 'backgroundSecondary',
      border: '1px solid transparent',
      whiteSpace: 'nowrap',
      '& svg, & img': {
        fill: 'currentColor'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'text',
      '& img, & svg': {
        mr: 2
      },
      '@media (hover: hover)': {
        '&:hover': {
          bg: 'backgroundSecondaryHover'
        }
      },
      ':focus': {
        outline: 'none',
        bg: 'backgroundSecondary',
        '--box-shadow-color': 'primary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        opacity: 0.2
      }
    }
  },
  breakpoints: ['320px', '600px', '768px', '1025px', '1280px', '1440px'],
  zIndices: {
    transcript: 5,
    themeSelector: 4
  }
})

export default theme
