import React from 'react'
import { Flex } from 'rebass'
import { ReactComponent as CloseIcon } from './assets/close.svg'
import styles from './styles'

interface TranscriptHeaderProps {
  close: () => void
  downloadTranscript: () => void
}
const TranscriptHeader: React.FC<TranscriptHeaderProps> = ({ close }) => {
  return (
    <Flex sx={styles.header} className="transcript-header">
      <Flex>Transcript</Flex>
      {/* <Flex sx={styles.closeIcon} onClick={close}>
        <CloseIcon />
      </Flex> */}
    </Flex>
  )
}

export default TranscriptHeader
