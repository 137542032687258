import React, { useContext } from 'react'
import { trackHandler, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { Button, Text } from 'rebass'

const TranscriptButton = () => {
  const { transcriptOpen } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)

  const openTranscript = () => {
    dispatch({ type: 'openTranscript', payload: true })
  }

  const closeTranscript = () => dispatch({ type: 'closeTranscript' })

  const handleClick = () => {
    transcriptOpen
      ? closeTranscript()
      : trackHandler(openTranscript, 'back-btn')()
  }

  return (
    <Button variant="whiteInverted" onClick={handleClick}>
      <span
        style={{
          marginRight: '8px'
        }}
      >
        &bull;
      </span>{' '}
      <Text>{transcriptOpen ? 'Hide' : 'Show'} Transcript</Text>
    </Button>
  )
}

export default TranscriptButton
