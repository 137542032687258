export const styles = {
  errorContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 5,
    backgroundColor: 'rgba(0,0,0,0.8)',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  error: {
    container: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      alignSelf: 'center',
      width: ['95%', '95%', 'auto', 'auto', 560, 560],
      zIndex: 1,
      minHeight: 233,
      maxHeight: 233,
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'backgroundPrimary',
      borderRadius: 'card',
      backdropFilter: 'blur(50px)',
      color: 'text'
    },
    warning: {
      minHeight: 136,
      width: ['95%', '95%', 'auto', 'auto', 560, 560],
      borderRadius: '0 8px 8px 0',
      justifyContent: 'center',
      flexDirection: 'column',
      px: 7,
      py: 7
    },
    divider: {
      background: '#C4C4C4',
      opacity: 0.2,
      width: '100%',
      height: '1px',
      my: 8
    },
    heading: {
      display: 'inline-flex',
      width: 'fit-content',
      fontSize: 5
    },
    icon: {
      color: 'error',
      alignItems: 'center',
      width: '24px',
      mr: 2
    },
    message: {
      my: 8,
      fontSize: 2
    },
    closeButton: {
      maxWidth: 'fit-content'
    }
  },
  fatal: {
    container: {
      minHeight: 257,
      px: 15
    },
    childrenContainer: {
      mt: 10
    },
    icon: {
      display: 'block',
      color: 'error'
    },
    heading: {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      p: 0
    },
    message: {
      mt: 10,
      fontSize: 1,
      textAlign: 'center'
    },
    backButton: {
      mt: 10
    }
  }
}

export default styles
