export const styles = {
  mainContainer: {
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      display: 'none'
    },
    '& *': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    label: 'homeMainContainer',
    minWidth: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'flex-start',
    flexDirection: ['column', 'column', 'column', 'column', 'row', 'row'],
    justifyContent: [
      'flex-start',
      'flex-start',
      'flex-start',
      'flex-start',
      'normal',
      'normal'
    ],
    width: '100%',
    '& > video': {
      objectFit: 'cover',
      width: '100vw',
      height: [
        'calc(100vh - 48px)',
        'calc(100vh - 48px)',
        '100vh',
        '100vh',
        '100vh',
        '100vh'
      ],
      minHeight: ['auto', 'auto', '100vh', '100vh', '100vh'],
      position: ['static', 'static', 'static', 'static', 'fixed', 'fixed'],
      top: 0,
      left: 0,
      zIndex: 0
    },
    maxHeight: '100%'
  },
  videoContainer: {
    my: 2,
    width: '28%',
    display: ['none', 'none', 'none', 'none', 'block', 'block']
  },
  logo: {
    backgroundColor: [
      'rgba(0,0,0,0.3)',
      'rgba(0,0,0,0.3)',
      'rgba(0,0,0,0.3)',
      'rgba(0,0,0,0.3)',
      'transparent',
      'transparent'
    ],
    width: ['100%', '100%', '100%', '100%', 'auto', 'auto'],
    zIndex: '5',
    position: 'absolute',
    top: 0,
    left: [0, 0, 0, 0, '16px', '16px'],
    display: 'flex',
    justifyContent: [
      'center',
      'center',
      'center',
      'center',
      'flex-start',
      'flex-start'
    ],
    alignItems: [
      'center',
      'center',
      'center',
      'center',
      'flex-start',
      'flex-start'
    ],
    '& img': {
      mt: ['16px', '16px', '16px', '16px', '32px', '32px'],
      display: 'block',
      width: ['70%', '70%', '70%', '70%', 'auto', 'auto'],
      p: 2
    }
  },
  textContainer: {
    flexDirection: 'column',
    alignSelf: 'flex-end',
    m: [0, 0, 0, 0, 6, 6],
    backgroundColor: 'backgroundPrimary',
    borderRadius: ['unset', 'unset', 'unset', 'unset', '8px', '8px'],
    zIndex: 5,
    position: ['fixed', 'fixed', 'fixed', 'fixed', 'static', 'static'],
    bottom: [0, 0, 0, 0, 'unset', 'unset'],
    color: 'text',
    width: ['100%', '100%', '100%', '100%', 560, 560],
    p: [6, 6, 10, 10, 10, 10],
    backdropFilter: 'blur(50px)'
  },
  title: {
    fontSize: ['16px', '16px', '24px', '24px', '24px', '24px'],
    lineHeight: ['16px', '16px', '32px', '32px', '32px', '32px'],
    letterSpacing: '-0.01em'
  },
  subtitle: {
    fontSize: ['12px', '12px', '16px', '16px', '16px', '16px'],
    lineHeight: ['16px', '16px', '24px', '24px', '24px', '24px'],
    letterSpacing: '-0.01em',
    opacity: 0.6
  },
  letsChatButton: {
    fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
    lineHeight: ['17px', '17px', '19px', '19px', '19px', '19px'],
    fontWeight: 'normal',
    py: [3, 2],
    px: [6, 5]
  },
  actions: {
    label: 'actions',
    flexDirection: ['column', 'column', 'column', 'column', 'row', 'row'],
    mt: 6,
    mb: [10, 10, 12, 12, 12, 12],
    '& > *:not(:last-child)': {
      mr: [0, 0, 0, 0, 6, 6],
      mb: [2, 2, 2, 2, 0, 0]
    }
  },
  privacyPolicyLink: {
    textDecoration: 'underline',
    color: 'currentColor'
  },
  titleContainer: {
    display: 'flex',
    width: '100%'
  }
}
export default styles
