import { useWindowWidth } from '@react-hook/window-size'
import React, { useState } from 'react'
import { Button, Flex, Text, Image, Box } from 'rebass'
import { useSupportedBrowser } from 'uneeq-react-core'
import styles from './styles'

import portaitImg from '../assets/landing/LandingPageVideoPortrait.jpg'
import landingImg from '../assets/landing/LandingPageVideo.jpg'
import portaitVideo from '../assets/landing/LandingPageVideoPortrait.mp4'
import landingVideo from '../assets/landing/LandingPageVideo.mp4'
import MayaUnsupportedBrowser from '../../customPlugins/MayaUnsupportedBrowser'

const SophieVideo = () => {
  const width = useWindowWidth()
  const smallScreen = width < 1024

  return (
    <video
      playsInline
      autoPlay
      muted
      loop
      poster={smallScreen ? portaitImg : landingImg}
    >
      <source
        src={smallScreen ? portaitVideo : landingVideo}
        type="video/mp4"
      />
    </video>
  )
}

interface HomeProps {
  startSession: (speak: boolean) => void
  restart?: () => void
  headingMessage: string
  disableDigitalHuman: boolean
  logo: string
}

const Home: React.FC<HomeProps> = ({
  startSession,
  restart,
  headingMessage,
  disableDigitalHuman,
  logo
}) => {
  const { isBrowserSupported } = useSupportedBrowser()

  const [showUnsupportedAlert, setShowUnsupportedAlert] = useState(false)

  const StartButton = ({ speak, sx, ...props }: any) => {
    const handleClick =
      speak && !isBrowserSupported
        ? () => setShowUnsupportedAlert(true)
        : () => startSession(speak)

    return (
      <Button
        {...props}
        onClick={handleClick}
        sx={{ ...styles.letsChatButton, ...sx }}
      >
        {speak ? 'Audio' : 'Chatbot'}
      </Button>
    )
  }

  return (
    <Flex sx={styles.mainContainer}>
      <SophieVideo />
      {showUnsupportedAlert && (
        <MayaUnsupportedBrowser
          close={() => setShowUnsupportedAlert(false)}
          continueChatMode={() => {
            setShowUnsupportedAlert(false)
            startSession(false)
          }}
        />
      )}
      {logo && (
        <Box sx={styles.logo}>
          <Image src={logo} alt="Logo"></Image>
        </Box>
      )}
      <Flex sx={styles.textContainer}>
        <Text sx={styles.title}>
          Hello, my name is <strong>Maya, your AI health assistant.</strong>
          <br />
          {headingMessage}
        </Text>

        {headingMessage && (
          <Flex sx={styles.actions}>
            {!disableDigitalHuman && (
              <StartButton variant="primary" speak={true} />
            )}
            <StartButton variant="whiteInverted" speak={false} />
          </Flex>
        )}
        <Text sx={styles.subtitle}>
          We take your privacy seriously. For more information, read our{' '}
          <Text
            as="a"
            href="https://mayamd.ai/privacy-policy/"
            target="_blank"
            rel="noreferrer noopener"
            sx={styles.privacyPolicyLink}
          >
            privacy policy
          </Text>
          &nbsp;and&nbsp;
          <Text
            as="a"
            href="https://mayamd.ai/terms-and-conditions/"
            target="_blank"
            rel="noreferrer noopener"
            sx={styles.privacyPolicyLink}
          >
            terms of conditions
          </Text>
        </Text>
      </Flex>
    </Flex>
  )
}

export default Home
