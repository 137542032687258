import React, { useEffect, useRef, useState } from 'react'
import { useIsSmallScreen } from 'uneeq-react-core'
import { Box } from 'rebass'
import Hls from 'hls.js'
interface LoadingVideoProps {
  onFinishVideo?: Function
}

const initializeMetrics = () => {
  // @ts-ignore
  const muxLib = mux
  if (typeof muxLib !== 'undefined') {
    muxLib.monitor('#introductionVideo', {
      debug: false,
      data: {
        // TODO: add to vercel .env
        env_key: process.env.REACT_APP_MUX_ENV || '5j9vdn4r8de8ubj30tdiknn8i',

        // Metadata
        player_name: 'HTML5 with HLSJS', // ex: 'My Main Player'
        player_init_time: Date.now(), // ex: 1451606400000
        video_title: 'Introduction Video'
        // ... and other metadata
      }
    })
  }
}

const LoadingVideo = React.memo(({ onFinishVideo }: LoadingVideoProps) => {
  const isSmallScreen = useIsSmallScreen()
  const heightRef = useRef<HTMLElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoWidth, setVideoWidth] = useState(0)
  const [shift, setShift] = useState(0)

  useEffect(() => {
    if (videoRef.current) {
      // TODO: move to .env?
      initializeMetrics()
      const videoSrc =
        'https://stream.mux.com/BQhWXGYs2M2e028DuCm801DFDSm00cUHr02FbJy8zhV00TAA.m3u8'
      const mp4VideoSrc =
        'https://stream.mux.com/BQhWXGYs2M2e028DuCm801DFDSm00cUHr02FbJy8zhV00TAA/high.mp4'
      if (Hls.isSupported()) {
        var hls = new Hls()
        hls.loadSource(videoSrc)
        hls.attachMedia(videoRef.current)
      }
      // hls.js is not supported on platforms that do not have Media Source
      // Extensions (MSE) enabled.
      //
      // When the browser has built-in HLS support (check using `canPlayType`),
      // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
      // element through the `src` property. This is using the built-in support
      // of the plain video element, without using hls.js.
      //
      // Note: it would be more normal to wait on the 'canplay' event below however
      // on Safari (where you are most likely to find built-in HLS support) the
      // video.src URL must be on the user-driven white-list before a 'canplay'
      // event will be emitted; the last video event that can be reliably
      // listened-for when the URL is not on the white-list is 'loadedmetadata'.
      else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = videoSrc
      } else {
        videoRef.current.src = mp4VideoSrc
      }
    }
  }, [])

  useEffect(() => {
    if (heightRef.current) {
      const { width, height } = heightRef.current.getBoundingClientRect()
      if (width && height && isSmallScreen) {
        const aspectRatioWidth = (height * 16) / 9 // full width of the video at this height
        const avatarLeft = aspectRatioWidth * 0.65 // where the avatar is within video
        const center = width / 2 // the center of the frame - where we want the avatar to be

        const shiftToCenter = Math.min(0, center - avatarLeft)

        setShift(shiftToCenter) // How far to shift the video over to center
        setVideoWidth(
          aspectRatioWidth +
            Math.max(0, width - aspectRatioWidth - shiftToCenter)
        ) // If remains a black space in the right we zoom in to fill it with the video.
      }
    }
  }, [isSmallScreen])

  const handleEndedVideo = () => {
    if (onFinishVideo) {
      onFinishVideo()
    }
  }

  const videoStyles = isSmallScreen
    ? {
        width: videoWidth,
        height: '100vh',
        marginLeft: shift
      }
    : {
        width: '100vw',
        height: '100vh',
        marginLeft: 0
      }

  return (
    <React.Suspense fallback={<></>}>
      <Box
        sx={{ height: '100vh', zIndex: 999, backgroundColor: '#727172' }}
        ref={heightRef}
      >
        <video
          id="introductionVideo"
          ref={videoRef}
          playsInline
          autoPlay
          poster="https://image.mux.com/BQhWXGYs2M2e028DuCm801DFDSm00cUHr02FbJy8zhV00TAA/thumbnail.png?time=0.0"
          onEnded={handleEndedVideo}
          onPauseCapture={() => {
            // Mobile devices stop playing video after the DH video is fully loaded.
            if (videoRef.current) {
              if (!videoRef.current.ended) {
                videoRef.current.play()
              }
            }
          }}
          style={{
            ...videoStyles,
            objectFit: 'cover'
          }}
        ></video>
      </Box>
    </React.Suspense>
  )
})

export default LoadingVideo
