import React, { useEffect, useState } from 'react'
import { Box } from 'rebass'

import { ThemeProvider } from 'emotion-theming'

import theme, { THEMES } from '../theme'
import Home from './Home'

import SessionTimedOut from './SessionTimedOut'
import DigitalHuman from './DigitalHuman'
import { getToken } from '../socket'
import ThemeSelector from './ThemeSelector/ThemeSelector'
import FatalError from '../customPlugins/MayaErrors/FatalError'

const backgroundStyle = {
  label: 'wrapper',
  width: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'right bottom',
  backgroundColor: '#004C9C',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0
}

const getTheme = (queryTheme: string | null) => {
  if (!queryTheme) {
    return window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? THEMES.DARK
      : THEMES.DEFAULT
  }

  if (queryTheme.toLowerCase() === 'dark') {
    return THEMES.DARK
  }
  return THEMES.DEFAULT
}

const App = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const embeddedApp = Boolean(queryParams.get('embedded'))

  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState(embeddedApp ? 'digitalHuman' : 'welcome')
  const [speak, setSpeak] = useState(true)
  const [headingMessage, setHeadingMessage] = useState('')
  const [logo, setLogo] = useState('')
  const [loadingTips, setLoadingTips] = useState([])
  const [disableDH, setDisableDH] = useState(true)
  const [initError, setInitError] = useState('')

  const restart = () => goTo('welcome')

  // @ts-ignore
  var viewport = window.visualViewport
  const [height, setHeight] = useState(viewport?.height)

  const [activeTheme, setActiveTheme] = useState(THEMES.DARK)

  useEffect(() => {
    viewport?.addEventListener('resize', () => {
      step === 'digitalHuman' && setHeight(viewport.height)
    })
  }, [step, viewport])

  useEffect(() => {
    const scrollToTop = () => {
      if (step === 'digitalHuman') {
        document.documentElement.scrollTop = 0
      }
    }
    scrollToTop()

    // @ts-ignore
    window.visualViewport?.addEventListener('scrollEnd', scrollToTop)
    // @ts-ignore
    return window.visualViewport?.removeEventListener('scrollEnd', scrollToTop)
  })

  getToken()
    .then(({ message, disableDigitalHuman, logo, loadingTips }: any) => {
      const headingMessage = message || ' '
      setHeadingMessage(headingMessage)
      setDisableDH(disableDigitalHuman)
      setLogo(logo)
      setLoadingTips(loadingTips)

      if (embeddedApp) {
        setSpeak(!disableDigitalHuman)
        goTo('digitalHuman')
      }
    })
    .catch(({ message }: any) => {
      setInitError(message)
    })

  return (
    <ThemeProvider theme={theme(activeTheme)}>
      <Box
        sx={{
          ...backgroundStyle,
          height: height || '100%',
          overflow: step === 'welcome' ? 'visible' : 'hidden'
        }}
      >
        {initError && (
          <FatalError
            errorTitle="Something went wrong"
            errorMessage={initError}
            clearError={() => window.location.reload()}
            buttonText="Try again"
          ></FatalError>
        )}
        {step === 'digitalHuman' ? (
          <DigitalHuman
            speak={speak}
            loadingTips={loadingTips}
            onTimedOut={() => goTo('timed-out')}
            restart={restart}
            onSessionEnded={() => goTo('restart')}
            embeddedMode={embeddedApp}
          />
        ) : step === 'timed-out' ? (
          <SessionTimedOut restart={restart} />
        ) : (
          <Home
            startSession={speak => {
              setSpeak(speak)
              goTo('digitalHuman')
            }}
            restart={() => goTo('restart')}
            headingMessage={headingMessage}
            disableDigitalHuman={disableDH}
            logo={logo}
          />
        )}
      </Box>
    </ThemeProvider>
  )
}

export default App
