import React, { useContext, useEffect, useRef } from 'react'
import { Box, Flex, Text } from 'rebass'
import { UneeqContext, useTranscript, useUneeqState } from 'uneeq-react-core'
import styles from './styles'
import TranscriptHeader from './TranscriptHeader'
import TranscriptMessage from './TranscriptMessage'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

const MotionBox: any = motion.custom(Box)

interface TranscriptItemCommon {
  type: String
  time: Date
}

export interface TranscriptItem {
  [key: string]: any | TranscriptItemCommon
}

const Transcript: React.FC = () => {
  const { dispatch } = useContext(UneeqContext)
  const { transcriptOpen } = useUneeqState()
  const { downloadTranscript, transcript } = useTranscript()
  const { t } = useTranslation()

  // autoscroll to bottom
  const scrollArea = useRef<HTMLElement>()
  useEffect(() => {
    if (scrollArea && scrollArea.current) {
      scrollArea.current.scrollTop =
        scrollArea?.current.scrollHeight - scrollArea?.current.clientHeight
    }
  }, [transcriptOpen, transcript])

  const close = () => dispatch({ type: 'closeTranscript' })

  const height = window.screen.availHeight

  return (
    <AnimatePresence>
      {transcriptOpen && (
        <MotionBox
          initial={{ transform: `translate(0px, 50px)` }}
          animate={{ transform: `translate(0px, 0px)` }}
          exit={{
            // transform: `translate(0px, ${Math.max(300, height * 0.3)}px)`
            transform: `translate(0px, ${height * 1.5}px)`
          }}
          sx={styles.container}
        >
          <Flex variant="transcript" sx={styles.transcript}>
            <TranscriptHeader
              close={close}
              downloadTranscript={downloadTranscript}
            />
            <Flex
              ref={scrollArea}
              className="transcript-content"
              sx={styles.scrollArea}
            >
              <Box sx={styles.scrollContent}>
                {transcript.length ? (
                  transcript.map((item: TranscriptItem, i: number) => (
                    <TranscriptMessage
                      key={item.time.valueOf()}
                      item={item}
                      showUndo={i !== transcript.length - 1}
                    />
                  ))
                ) : (
                  <Text>{t('Transcript.noTranscriptAvailable')}</Text>
                )}
              </Box>
            </Flex>
          </Flex>
        </MotionBox>
      )}
    </AnimatePresence>
  )
}

export default Transcript
